import Enum from '@/utils/Enum';

const PaymentMethodEnum = [
  {
    value: 0,
    name: '预收款',
  },
  {
    value: 1,
    name: '账期收款',
  },
  {
    value: 2,
    name: '背靠背',
  },
];

export default new Enum(PaymentMethodEnum);
export { PaymentMethodEnum };
