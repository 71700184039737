<template>
  <component :is="detail[templateType]" :id="id"></component>
</template>

<script>
import Maintenance from './components/Detail/Maintenance.vue';
import SingleRepair from './components/Detail/SingleRepair.vue';
import Warranty from './components/Detail/Warranty.vue';
import Technology from './components/Detail/Technology.vue';
import Relocation from './components/Detail/Relocation.vue';
import HosipitalAsset from './components/Detail/HosipitalAsset.vue';

export default {
  name: 'SaleContractDetail',
  props: {
    templateType: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data () {
    return {
      detail: {
        MAINTENANCE: Maintenance,
        SINGLE_REPAIR: SingleRepair,
        QUOTATION_LETTER: SingleRepair,
        WARRANTY: Warranty,
        TECHNOLOGY_SERVICE: Technology,
        RELOCATION: Relocation,
        HOSPITAL_ASSET_MANAGEMENT: HosipitalAsset,
      },
    };
  },
};
</script>

