<template>
  <div class="single_repair_contract_detail_wrapper">
    <div class="header">
      <div>
        <a-button icon="arrow-left" size="small" @click="back" />
        <span class="page_type">详情</span>
      </div>
    </div>
    <div class="content">
      <div class="common flex-row">
        <div class="left">
          <div class="basic-info">
            <div class="img-wrapper">
              <a-avatar shape="square" :size="26" :src="titlePrefix" />
            </div>
            <div>
              <div class="code"><span style="display: inline-block; width: 15em;">销售合同号：{{ info.code }}</span><a-button
                  size="small" type="primary" @click="handleViewContract">合同预览</a-button></div>
              <div class="customer">{{ info.name }} <span :class="`status-${info.executionStatus}`">{{
                EXECUTION_STATUS_ARRAY_MAP[info.executionStatus] }}</span>
              </div>
            </div>
          </div>
          <a-tabs default-active-key="1" @change="handleActiveChange">
            <a-tab-pane key="1" tab="合同信息">
            </a-tab-pane>
            <a-tab-pane key="2" tab="日志动态">
            </a-tab-pane>
            <a-tab-pane key="3" tab="关联审批">
            </a-tab-pane>
            <a-tab-pane key="4" tab="收款信息">
            </a-tab-pane>
            <a-tab-pane key="5" tab="附件信息">
            </a-tab-pane>
            <a-tab-pane key="7" tab="订单结算">
            </a-tab-pane>
            <a-tab-pane v-if="!isDirectlySalesCompany" key="8" tab="关联合同">
            </a-tab-pane>
          </a-tabs>
        </div>
        <div class="bg_money_wrapper">
          <div class="title">合同金额</div>
          <div class="value">{{ info.totalAmount | formatMoney }}元</div>
        </div>
      </div>
      <template v-if="activeKey === '1'">
        <div class="common">
          <div class="title gap-12 ">基本信息</div>
          <a-descriptions>
            <a-descriptions-item label="销售模式">
              {{ info.saleModelDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="业务类型">
              {{ info.businessTypeDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="合同模板">
              {{ info.templateTypeDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="客户抬头">
              {{ info.customerHead }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商抬头">
              {{ info.accountingUnit }}
            </a-descriptions-item>
            <a-descriptions-item label="最终用户">
              {{ info.finalCustomerName }}
            </a-descriptions-item>
            <a-descriptions-item label="发票类型">
              {{ info.invoiceTypeDesc }}
            </a-descriptions-item>
            <a-descriptions-item label="税率">
              {{ info.tariff ? parseInt(info.tariff * 100) + '%' : '' }}
            </a-descriptions-item>
            <a-descriptions-item label="生效起止日">
              {{ formatDate(info.effectiveDate) }} ~ {{
                formatDate(info.contractDate) }}
            </a-descriptions-item>
            <a-descriptions-item label="业务员">
              {{ info.salesmanName }}
            </a-descriptions-item>
            <a-descriptions-item label="签订日期">
              {{ formatDate(info.signedDate) }}
            </a-descriptions-item>
            <a-descriptions-item label="结算方式">
              {{ PaymentMethodEnumMap[info.paymentMethod] }}
            </a-descriptions-item>
            <a-descriptions-item label="创建时间">
              {{ formatDate(info.createTime, 'YYYY-MM-DD HH:mm:ss') }}
            </a-descriptions-item>
            <a-descriptions-item label="创建人">
              {{ info.createUserName }}
            </a-descriptions-item>
            <a-descriptions-item label="收款账号" :span="3">
              {{ `${info.bankOfDeposit}_${info.accounts}` }}
            </a-descriptions-item>
            <a-descriptions-item label="合同主题" :span="3">
              {{ info.name }}
            </a-descriptions-item>
            <a-descriptions-item label="合同备注" :span="3">
              {{ info.contractRemarks }}
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div class="common">
          <div class="title gap-12 ">履约标的</div>
          <xz-table :columns="deviceColumns" :data="info.deviceList" rowKey="id">
            <template slot="footer" slot-scope="currentPageData">
              <div>
                <b>含税总金额（元）：</b>{{ currentPageData.reduce((pre, cur) => pre + cur.taxAmount, 0) ? currentPageData.reduce((pre, cur) => pre + cur.taxAmount, 0).toFixed(2) : '' }}
              </div>
            </template>
          </xz-table>
          <div class="extra-input">
            <span class="title">附加设备系统/服务</span>
            <div>{{ info.additionalDeviceSystemServe }}</div>
          </div>
        </div>
        <div class="common">
          <div class="title gap-12 ">计费清单</div>
          <xz-table :columns="billColumns" :data="billList" rowKey="id">
            <span slot="warrantyPeriod" slot-scope="text, record">
              {{ record.warrantyPeriod || '' }} {{ record.warrantyPeriodUnit || '' }}
            </span>
            <template slot="footer" slot-scope="currentPageData">
              <div>
                <b>含税总金额（元）：</b>{{ currentPageData.reduce((pre, cur) => pre + cur.taxAmount, 0) ? currentPageData.reduce((pre, cur) => pre + cur.taxAmount, 0).toFixed(2) : '' }}
              </div>
            </template>
          </xz-table>
        </div>
      </template>
      <template v-if="activeKey === '2'">
        <log :id="info.id" :remindType="1"></log>
      </template>
      <template v-if="activeKey === '3'">
        <AuditList :id="info.id"></AuditList>
      </template>
      <template v-if="activeKey === '4'">
        <a-tabs default-active-key="plan" style="background-color: #fff;">
          <a-tab-pane key="plan" tab="收款计划" style="padding: 0 16px 16px;">
            <xz-table :columns="planColumns" :data="info.paymentPlanList" :scroll="{ x: '100%', y: 540 }" :rowKey="'id'">
            </xz-table>
          </a-tab-pane>
          <a-tab-pane key="actual" tab="实付信息" style="padding: 0 16px 16px;">
            <xz-table :columns="actualColumns" :data="flows" :scroll="{ x: '100%', y: 540 }" :rowKey="'id'"></xz-table>
          </a-tab-pane>
        </a-tabs>
      </template>
      <template v-if="activeKey === '5'">
        <AttachMentList :tableData="info.annexList" :annexNameCache="annexNameCache"></AttachMentList>
      </template>
      <template v-if="activeKey === '7'">
        <orders :contractId="info.id" :saleOrderType="'saleOrder'"></orders>
      </template>
      <template v-if="activeKey === '8'">
        <div class="extra-input" style="padding: 16px 16px 0; font-weight: bold; display: flex; justify-content: space-between;">
          直营销售合同： {{ purchaseInfo.pcCode }}
          <div style="flex: 1; text-align: right;">
            <span style="margin-right: 2em;">合同金额： {{ purchaseInfo.totalAmount }}</span>
            <span style="margin-right: 2em;">创建人: {{ purchaseInfo.createUserName }}</span>
            <span>业务员：{{ purchaseInfo.salesmanName }}</span>
          </div>
        </div>
        <orders key="purchaseOrder" :contractId="info.purchaseContractIds[0]" :saleOrderType="'purchaseOrder'"></orders>
      </template>
    </div>
    <a-modal v-model="visible" title="合同预览" :width="900" :bodyStyle="{ padding: '0 12px' }" centered>
      <SingleRepairContractContract v-if="info.templateType === 'SINGLE_REPAIR'" :contractData="contractData"></SingleRepairContractContract>
      <QuotationLetterContract v-else  :contractData="contractData"></QuotationLetterContract>
      <template slot="footer">
        <span></span>
      </template>
    </a-modal>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import { saleContractDetail, saleCapitalFlow, purchaseContractDetail } from '@/api/contract';
import * as Moment from 'dayjs';
import PaymentMethodEnumMap from '@/enums/PaymentMethodEnum';
import { mapGetters, mapActions } from 'vuex';
import Log from './Log.vue';
import AuditList from './AuditList.vue';
import AttachMentList from './AttachMentList.vue';
import Orders from './Orders.vue';
import SingleRepairContractContract from '@/components/fileTemplates/singleRepairContract.vue';
import QuotationLetterContract from '@/components/fileTemplates/quotationLetterContract.vue';
import { numberTranToUpperCN, numberTranToCN } from '@/utils/chinesePrice';
import directlySaleCompanyMixin from '../../../mixins/directlySaleCompanyMixin';

const EXECUTION_STATUS_ARRAY_MAP = ['草稿', '审核中', '已驳回', '执行中', '已完结', '已取消'];
const QUOTATION_ENUM = ['', '人工', '备件'];

const YEAR_MILLSECONDS = 24 * 3600 * 1000 * 365;

export default {
  name: 'SaleContractDetail',
  components: {
    xzTable,
    Log,
    AuditList,
    AttachMentList,
    Orders,
    SingleRepairContractContract,
    QuotationLetterContract,
  },
  filters: {
    formatMoney (v) {
      return v ? v.toLocaleString() : '';
    },
  },
  computed: {
    ...mapGetters([
      'annexNameCache',
      'supplierCache',
    ]),
  },
  mixins: [directlySaleCompanyMixin],
  data () {
    return {
      deviceColumns: [
        {
          title: '序号',
          key: 'index',
          width: 80,
          ellipsis: true,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '设备名称',
          dataIndex: 'deviceName',
          key: 'deviceName',
          width: 160,
          ellipsis: true,
        },
        {
          title: '设备型号',
          dataIndex: 'deviceModel',
          key: 'deviceModel',
          width: 160,
          ellipsis: true,
        },
        {
          title: '设备品牌',
          dataIndex: 'deviceBrandName',
          key: 'deviceBrandName',
          width: 160,
          ellipsis: true,
        },
        {
          title: '生产厂家',
          dataIndex: 'deviceEnterpriseName',
          key: 'deviceEnterpriseName',
          width: 160,
          ellipsis: true,
        },
        {
          title: '厂家编号',
          dataIndex: 'manufacturerCode',
          key: 'manufacturerCode',
          width: 160,
          ellipsis: true,
        },
        {
          title: '设备序列号',
          dataIndex: 'deviceSerialNumber',
          key: 'deviceSerialNumber',
          width: 160,
          ellipsis: true,
        },
        {
          title: '位置',
          dataIndex: 'placeDepartment',
          key: 'placeDepartment',
          width: 160,
          ellipsis: true,
        },
        {
          title: '故障描述',
          dataIndex: 'faultDescription',
          key: 'faultDescription',
          width: 160,
          ellipsis: true,
        },
        {
          title: '税率',
          dataIndex: 'taxRate',
          key: 'taxRate',
          width: 160,
          ellipsis: true,
          customRender: (text) => {
            return text ? parseInt(text * 100 + '%') : '';
          },
        },
        {
          title: '含税金额',
          dataIndex: 'taxAmount',
          key: 'taxAmount',
          width: 160,
          ellipsis: true,
        },
      ],
      planColumns: [
        {
          title: '结算类项目',
          dataIndex: 'name',
          key: 'name',
          width: 160,
          ellipsis: true,
        },
        {
          title: '期数/次数',
          dataIndex: 'periodsNumber',
          key: 'periodsNumber',
          width: 160,
          ellipsis: true,
        },
        {
          title: '收款日期',
          dataIndex: 'paymentDate',
          key: 'paymentDate',
          width: 160,
          ellipsis: true,
          customRender: (text) => {
            return this.formatDate(text);
          },
        },
        {
          title: '收款金额',
          dataIndex: 'paymentAmount',
          key: 'paymentAmount',
          width: 160,
          ellipsis: true,
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          width: 160,
          ellipsis: true,
        },
      ],
      actualColumns: [
        {
          title: '收款日期',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 160,
          ellipsis: true,
        },
        {
          title: '收款金额',
          dataIndex: 'amount',
          key: 'amount',
          width: 160,
          ellipsis: true,
        },
        {
          title: '支付方式',
          dataIndex: 'paymentTypeDesc',
          key: 'paymentTypeDesc',
          width: 160,
          ellipsis: true,
        },
        {
          title: '流水号',
          dataIndex: 'code',
          key: 'code',
          width: 160,
          ellipsis: true,
        },
      ],
      billColumns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 80,
          ellipsis: true,
        },
        {
          title: '计费类目',
          dataIndex: 'type',
          key: 'type',
          width: 160,
          ellipsis: true,
          customRender: (text) => {
            return QUOTATION_ENUM[text];
          },
        },
        {
          title: '计费项名称',
          dataIndex: 'name',
          key: 'name',
          width: 160,
          ellipsis: true,
        },
        {
          title: '零备件号',
          dataIndex: 'deviceRegisterCode',
          key: 'deviceRegisterCode',
          width: 160,
          ellipsis: true,
        },
        {
          title: '含税单价（元）',
          dataIndex: 'taxPrice',
          key: 'taxPrice',
          width: 160,
          ellipsis: true,
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
          width: 160,
          ellipsis: true,
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          width: 160,
          ellipsis: true,
        },
        {
          title: '质保期/保修期',
          dataIndex: 'warrantyPeriod',
          key: 'warrantyPeriod',
          width: 160,
          ellipsis: true,
          scopedSlots: { customRender: 'warrantyPeriod' },
        },
        {
          title: '含税金额',
          dataIndex: 'taxAmount',
          key: 'taxAmount',
          width: 160,
          ellipsis: true,
        },
      ],
      titlePrefix: require('../../../../../assets/title_prefix.svg'),
      activeKey: '1',
      info: {},
      EXECUTION_STATUS_ARRAY_MAP,
      PaymentMethodEnumMap,
      flows: [],
      billList: [],
      purchaseInfo: {},
      contractData: {
        partA: {},
        partB: {},
        effectiveDate: ['', '', ''],
        terminalDate: ['', '', ''],
        paymentList: [],
        paymentDays: '',
        deviceList: [],
        additionalDeviceSystemServe: '',
        totalAmountPerYear: '',
        chineseTotalAmountPerYear: '',
        totalAmount: '',
        taxRate: '',
        chineseTotalAmount: '',
        bankName: '',
        bankAccount: '',
        invoiceTypeName: '',
        attachmentList: [],
        billList: [],
      },
      visible: false,
    };
  },
  methods: {
    ...mapActions([
      'getAnnexName',
      'getSupplier',
    ]),
    back () {
      this.$router.back();
    },
    formatDate (text, format = 'YYYY-MM-DD') {
      return text ? Moment(text * 1).format(format) : '/';
    },
    getTotalAmountPerYear (totalAmount, contractDate) {
      let ret = '';

      if (totalAmount && contractDate && contractDate[0] && contractDate[1]) {
        ret = Math.round(totalAmount / (contractDate[1] - contractDate[0]) * YEAR_MILLSECONDS * 100) / 100;
      }

      return ret;
    },
    getDays (t1, t2) {
      t2 = t2 || Moment(Moment().format('YYYY-MM-DD')).valueOf();
      return parseInt((t1 - t2) / 3600 / 24 / 1000) + 1;
    },
    setSaleContractContent () {
      const totalAmountPerYear = this.getTotalAmountPerYear(this.info.totalAmount, [this.info.effectiveDate, this.info.contractDate]);

      const provideSaleBillList = (this.info.deviceList || []).map((v, pIndex) => {
        (v.billList || []).map((bill, index) => ({
          ...bill,
          index: `${pIndex + 1}-${index + 1}`,
        }));

        return v.billList;
      }).flat();

      const laborCosts = provideSaleBillList.filter(v => v.type * 1 === 1).reduce((pre, cur) => {
        return pre + cur.taxAmount * 1;
      }, 0);

      const laborCostsPerYear = this.getTotalAmountPerYear(laborCosts, [this.info.effectiveDate, this.info.contractDate]);

      const sparePartsCost = provideSaleBillList.filter(v => v.type * 1 === 2).reduce((pre, cur) => {
        return pre + cur.taxAmount * 1;
      }, 0);

      const sparePartsCostPerYear = this.getTotalAmountPerYear(sparePartsCost, [this.info.effectiveDate, this.info.contractDate]);

      const billTotal = laborCosts + sparePartsCost;

      this.contractData = {
        partA: {
          name: this.info.customerHead || '',
          finalCustomerName: this.info.finalCustomerName || '',
          legalPerson: '',
          address: '',
          postCode: '',
          telephone: '',
          fax: '',
        },
        partB: {
          name: this.info.accountingUnit || '',
          legalPerson: this.supplierCache.legalPerson || '',
          address: `${this.supplierCache.provinceName || ''}${this.supplierCache.cityName || ''}${this.supplierCache.areaName || ''}${this.supplierCache.address || ''}`,
          postCode: this.supplierCache.postcode || '',
          telephone: this.supplierCache.telePhone || '',
          fax: this.supplierCache.fax || '',
        },
        effectiveDate: this.info.effectiveDate ? this.formatDate(this.info.effectiveDate, 'YYYY-MM-DD', '').split('-') : ['', '', ''],
        terminalDate: this.info.contractDate ? this.formatDate(this.info.contractDate, 'YYYY-MM-DD', '').split('-') : ['', '', ''],
        paymentList: this.info.paymentPlanList.map((v, index) => ({
          ...v,
          index: numberTranToCN(index + 1),
          paymentDateArray: v.paymentDate ? this.formatDate(v.paymentDate, 'YYYY-MM-DD', '').split('-') : ['', '', ''],
        })),
        paymentDays: this.info.paymentPlanList?.[0]?.paymentDate ? this.getDays(this.info.paymentPlanList?.[0]?.paymentDate, this.info.signedDate) : '',
        deviceList: this.info.deviceList.map(v => ({
          ...v,
          startServiceTerm: v.serviceTermStart ? this.formatDate(v.serviceTermStart, 'YYYY-MM-DD', '') : '',
          endServiceTerm: v.serviceTermEnd ? this.formatDate(v.serviceTermEnd, 'YYYY-MM-DD', '') : '',
        })),
        additionalDeviceSystemServe: this.info.additionalDeviceSystemServe,
        totalAmountPerYear,
        chineseTotalAmountPerYear: totalAmountPerYear ? numberTranToUpperCN(totalAmountPerYear + '') : '',
        totalAmount: this.info.totalAmount || '',
        taxRate: this.info.tariff ? this.info.tariff * 100 + '%' : '',
        chineseTotalAmount: this.info.totalAmount ? numberTranToUpperCN(this.info.totalAmount + '') : '',
        bankName: this.info.bankOfDeposit || '',
        bankAccount: this.info.accounts || '',
        invoiceTypeName: this.info.invoiceTypeDesc || '',
        attachmentList: this.info.annexList || [],
        billList: provideSaleBillList.map(v => ({
          ...v,
          taxRate: v.taxRate ? v.taxRate + '%' : '',
          typeName: QUOTATION_ENUM[v.type],
        })),
        laborCostsPerYear,
        chineseLaborCostsPerYear: laborCostsPerYear ? numberTranToUpperCN(laborCostsPerYear + '') : '',
        sparePartsCostPerYear,
        chineseSparePartsCostPerYear: sparePartsCostPerYear ? numberTranToUpperCN(sparePartsCostPerYear + '') : '',
        billTotal,
        chineseBillTotal: billTotal ? numberTranToUpperCN(billTotal + '') : '',
      };
    },
    handleViewContract () {
      this.setSaleContractContent();

      this.visible = true;
    },
    async getDetail () {
      const { body } = await saleContractDetail({
        id: this.$decode(this.$route.query.id),
        findPaymentPlanFlag: true,
        findAnnexFlag: true,
        findDeviceFlag: true,
        findBillFlag: true,
      });

      this.info = body || {};

      this.billList = [];

      body.deviceList.forEach((device, pIndex) => {
        device.billList.forEach((bill, cIndex) => {
          bill.index = `${pIndex + 1}-${cIndex + 1}`;
        });

        this.billList.push(...device.billList);
      });

      if (this.info.purchaseContractIds && this.info.purchaseContractIds[0]) {
        this.getPurchaseDetail(this.info.purchaseContractIds[0]);
      }
    },
    async getPurchaseDetail (id) {
      const { body } = await purchaseContractDetail({
        id,
      });

      this.purchaseInfo = body || {};
    },
    async getFlow () {
      const { body } = await saleCapitalFlow({
        saleContractId: this.info.id,
        pageNum: 1,
        pageSize: 999,
      });

      this.flows = body?.list || [];
    },
    handleActiveChange (v) {
      this.activeKey = v;

      if (this.activeKey === '4') {
        this.getFlow();
      }
    },
  },
  created () {
    this.getDetail();
    this.getSupplier();
    this.getAnnexName();
  },
};
</script>

<style scoped lang="scss">
@mixin tag_round() {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: normal;
}

.single_repair_contract_detail_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 52px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;

    .page_type {
      font-size: 16px;
      font-weight: bold;
      margin-left: 1em;
    }
  }

  .content {
    flex: 1;
    padding: 16px 0 0;
    overflow: auto;

    .gap-12 {
      margin-bottom: 12px;
    }

    .common {
      background-color: #fff;
      padding: 12px;
      margin-bottom: 6px;

      &.flex-row {
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        padding-bottom: 0;

        .left {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .basic-info {
            display: flex;
            align-items: center;

            .img-wrapper {
              width: 40px;
              height: 40px;
              background-color: #E2F3FE;
              border-radius: 4px;
              margin-right: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .code {
              font-size: 14px;
              color: #929AA6;
            }

            .customer {
              font-size: 16px;
              font-weight: 600;

              .status-0,
              .status-5 {
                @include tag_round();
                color: #1F2733;
                background-color: #F2F4F7;
              }

              .status-1,
              .status-3 {
                @include tag_round();
                background-color: #E2F3FE;
                color: #237FFA;
              }

              .status-4 {
                @include tag_round();
                background-color: #E5FEEB;
                color: #14CA64;
              }

              .status-2 {
                @include tag_round();
                background-color: #FEE9E5;
                color: #FF5959;
              }
            }
          }

          /deep/ .ant-tabs-bar {
            margin: 0;

            .ant-tabs-nav {
              .ant-tabs-tab {
                margin-right: 16px;
              }
            }
          }
        }

        .bg_money_wrapper {
          width: 258px;
          height: 112px;
          background: url('../../../../../assets/bg_money.svg') no-repeat 0 0 / 96% 96%;
          padding: 20px;

          .title {
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
          }

          .value {
            font-size: 26px;
            font-weight: bold;
          }
        }
      }

      .title {
        font-weight: bold;
      }
    }
  }

  .extra-input {
    display: flex;
    align-items: flex-start;
    padding: 24px 0 12px 0;
    background: #fff;

    span {
      width: 9em;
      flex-shrink: 0;
    }
  }
}
</style>

